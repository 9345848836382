import OmniAural from "omniaural"
import React from "react"
import { Themes } from "../managers/ThemeManager"
import { Header } from "./screen-components/PageHeader"

const TermsPage = () => {
  const images = Themes.currentTheme().images

  const termsAndConditionsContent = () => {
    return (
      <div>
        <h1>{`END USER LICENSE AGREEMENT AND TERMS & CONDITIONS`}</h1>
        <h2>Application Terms of Use Agreement</h2>
        <p>{Themes.currentTheme().strings.termsOfUseAgreement}</p>
        <h2>Privacy Policy</h2>
        <p>{Themes.currentTheme().strings.privacyPolicy}</p>
      </div>
    )
  }

  return (
    <div>
      <Header/>
      <div className="body-container">
        <div className="terms-content">
          <div className="terms-back-button">
            <h3 onClick={() => window.history.back()}>
              <img
                src={images.bulletArrow}
                id="terms-back-button-image"
                style={{ marginRight: "10px" }}
              />
              Registration
            </h3>
          </div>
          {termsAndConditionsContent()}
        <div className="version-container">
          <div className="version-text">
            Website: v{require("../../package.json").version}
          </div>
          <div className="version-text">
            Server: v{OmniAural.state?.config?.version?.value()}
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default TermsPage
