import React, { useState } from "react"
import { Themes } from "../managers/ThemeManager"
import { Header } from "./screen-components/PageHeader"
import "./CertInstructions.css"
import iosCert from "../assets/iosCertError.png"
import androidCert from "../assets/chromeCertError.png"
import iosCert1 from "../assets/iosCertInstructions/allow-installing-dod-root-ca-ios.jpg"
import iosCert2 from "../assets/iosCertInstructions/settings-general-profile-device-management-installing-dod-root-ca-ios.jpg"
import iosCert3 from "../assets/iosCertInstructions/install-installing-dod-root-ca-ios.jpg"
import iosCert4 from "../assets/iosCertInstructions/install-2x--installing-dod-root-ca-ios.jpg"
import iosCert5 from "../assets/iosCertInstructions/done-installing-dod-root-ca-ios.jpg"
import iosCert6 from "../assets/iosCertInstructions/settings-general-about-certificate-trust-settings-installing-dod-root-ca-ios.jpg"
import iosCert7 from "../assets/iosCertInstructions/toggle-installing-dod-root-ca-ios.jpg"
import andCert1 from "../assets/androidCertInstructions/settings.png"
import andCert2 from "../assets/androidCertInstructions/security.png"
import andCert3 from "../assets/androidCertInstructions/encryption.png"
import andCert4 from "../assets/androidCertInstructions/selectCert.png"
import andCert5 from "../assets/androidCertInstructions/nameCert.png"

const CertInstructions = () => {
  const images = Themes.currentTheme().images

  const platform = getMobileOperatingSystem()
  const [androidTabDisplay, setAndroidTabDisplay] = useState("none")
  const [iosTabDisplay, setiosTabDisplay] = useState("none")

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "desktop"
    }

    if (/android/i.test(userAgent)) {
      return "android"
    }
    let isIpad =
      /Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if ((/iPad|iPhone|iPod/.test(userAgent) || isIpad) && !window.MSStream) {
      return "iOS"
    }

    return "desktop"
  }

  const renderIOSFaq = (shouldHideHeader) => {
    return (
      <div>
        <div style={{ display: shouldHideHeader ? "none" : "block" }}>
          <h1>{`DoD CERTIFICATE DOWNLOAD INSTRUCTIONS`}</h1>
          <div className="certImages">
            <img src={iosCert} />
          </div>
          <div>
            If you are receiving the warning shown above when visiting our website regarding your
            connection not being private, please follow the instructions below to download the
            correct certificate onto your device.
          </div>
          <br />
        </div>
        <h3>*MUST USE SAFARI WEB BROWSER</h3>
        <ol className="certList">
          <li>
            Download the DoD Root CA 3 cert here:{" "}
            <a href="https://nps.edu/documents/111151326/111164251/DoD-Root-CA-3.cer/2b94718d-5f88-8eaf-3bda-92e0fc548412?t=1619469888977">
              DoD Root CA 3
            </a>
          </li>
          <li>Click Allow to download configuration profile.</li>
          <img src={iosCert1} />
          <li>
            Go to Settings {">"} General {">"} Profiles and Device Management and tap on DoD Root CA
            3.
          </li>
          <img src={iosCert2} />
          <li>Tap Install and enter your passcode if asked.</li>
          <img src={iosCert3} />
          <li>Tap Install 2x to install certificate.</li>
          <img src={iosCert4} />
          <li>Tap Done on top right</li>
          <img src={iosCert5} />
          <li>
            Go back to Settings {">"} General {">"} About {">"} Certificate Trust Settings.
          </li>
          <img src={iosCert6} />
          <li>Toggle on DoD Root CA 3 and click Continue.</li>
          <img src={iosCert7} />
        </ol>
      </div>
    )
  }

  const renderAndroidFaq = (shouldHideHeader) => {
    return (
      <div>
        <div style={{ display: shouldHideHeader ? "none" : "block" }}>
          <h1>{`DoD CERTIFICATE DOWNLOAD INSTRUCTIONS`}</h1>
          <div className="certImages">
            <img src={androidCert} />
          </div>
          <div>
            If you are receiving the warning shown above when visiting our website regarding your
            connection not being private, please follow the instructions below to download the
            correct certificate onto your device.
          </div>
          <br />
        </div>
        <b>
          *Due to variability between Android devices, instructions for your device may vary
          slightly.
        </b>
        <ol className="certList">
          <li>
            Download the DoD Root CA 3 cert here:{" "}
            <a href="https://nps.edu/documents/111151326/111164251/DoD-Root-CA-3.cer/2b94718d-5f88-8eaf-3bda-92e0fc548412?t=1619469888977">
              DoD Root CA 3
            </a>
          </li>
          <li>Open your device's Settings app.</li>
          <img src={andCert1} className="androidCertImage" />
          <li>
            Tap security & privacy and then more security settings and then encryption &
            credentials.
          </li>
          <img src={andCert2} className="androidCertImage" />
          <li>Tap install a certificate and then Wi-Fi certificate.</li>
          <img src={andCert3} className="androidCertImage" />
          <li>Tap the menu</li>
          <li>Tap where you saved the certificate.</li>
          <li>Tap the file.</li>
          <img src={andCert4} className="androidCertImage" />
          <li>If needed, enter the key store password. Tap OK.</li>
          <li>Enter a name for the certificate.</li>
          <img src={andCert5} className="androidCertImage" />
          <li>Tap OK.</li>
        </ol>
      </div>
    )
  }

  const faqContent = () => {
    if (platform === "iOS") {
      return renderIOSFaq()
    } else if (platform === "android") {
      return renderAndroidFaq()
    } else {
      return (
        <div>
          <h1>{`DoD CERTIFICATE DOWNLOAD INSTRUCTIONS`}</h1>
          <div className="certImages">
            <img src={iosCert} /> <img src={androidCert} />
          </div>{" "}
          <div>
            If you are receiving the warning shown above when visiting our website regarding your
            connection not being private, please follow the instructions below to download the
            correct certificate onto your device.
          </div>
          <div className="accordianPanel">
            <div className="panel">
              <div
                className="namePlus"
                onClick={() => {
                  iosTabDisplay === "none" ? setiosTabDisplay("block") : setiosTabDisplay("none")
                }}>
                <div className="panelName">iOS</div>
                <div className="panelPlus">＋</div>
              </div>
              <div className="panelContent" style={{ display: iosTabDisplay }}>
                {renderIOSFaq(true)}
              </div>
            </div>
            <div className="panel">
              <div
                className="namePlus"
                onClick={() => {
                  androidTabDisplay === "none"
                    ? setAndroidTabDisplay("block")
                    : setAndroidTabDisplay("none")
                }}>
                <div className="panelName">Android</div>
                <div className="panelPlus">＋</div>
              </div>
              <div className="panelContent" style={{ display: androidTabDisplay }}>
                {renderAndroidFaq(true)}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <Header />
      <div className="body-container">
        <div className="faq-content">
          <div className="terms-back-button">
            <h3 onClick={() => window.history.back()}>
              <img
                src={images.bulletArrow}
                id="terms-back-button-image"
                style={{ marginRight: "10px" }}
              />
            </h3>
          </div>
          {faqContent()}
        </div>
      </div>
    </div>
  )
}

export default CertInstructions
